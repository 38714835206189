<template>
  <BCard id="seccion-content">
    <BCardHeader class="header-df">
      <BCol
        cols="12"
        md="6"
        class="pl-0"
      >
        <BMedia vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="MessageCircleIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Reviews
          </span>
          <!-- <small class="text-muted">SECCIÓN IV</small> -->
        </BMedia>
      </BCol>
    </BCardHeader>
    <BCard
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <BRow>
          <!-- Per Page -->
          <BCol
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </BCol>

          <!-- Search -->
          <BCol
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <BDropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="selectADR.text"
                right
                variant="outline-primary"
                class="ml-1"
              >
                <b-dropdown-item
                  v-for="sortOption in searchADR"
                  :key="sortOption.value"
                  @click="selectADR = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </BDropdown>
            </div>
          </BCol>
        </BRow>
      </div>

      <BTable
        ref="refPromocionListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(post.titulo)="data">
          <b-media vertical-align="center !important">
            <template #aside>
              <b-avatar
                size="28"
                :variant="`light-primary`"
              >
                <font-awesome-icon
                  :icon="data.item.icono || 'fa-solid fa-comment'"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.post.titulo }}
            </span>
          </b-media>
        </template>
        <template #cell(directorio)="data">
          <b-badge
            variant="light-secondary"
            class="mr-50"
          >
            {{ data.item.directorio.nombreComercial }}
          </b-badge>
          <b-badge
            variant="light-secondary"
            class="mr-50"
          >
            {{ data.item.directorio.tipoServicioTuristico.labelFrontend }}
          </b-badge>
        </template>
        <template #cell(estado)="data">
          <b-badge
            v-if="
              data.item.publishApprove == null &&
                data.item.publishDisapproval == null
            "
            pill
            variant="light-warning"
            class="text-capitalize"
          >
            REVISION
          </b-badge>

          <b-badge
            v-else
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            Inactivo
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <span
            v-if="
              data.item.publishApprove == null &&
                data.item.publishDisapproval == null
            "
          >
            <div class="d-inline wl200">
              <feather-icon
                v-b-tooltip.hover.v-success
                icon="CheckIcon"
                class="cursor-pointer mr-50"
                title="Aprobar"
                @click="approve(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.v-danger
                icon="XIcon"
                title="Desaprobar"
                class="cursor-pointer mr-50"
                @click="dessaapprove(data.item)"
              />
            </div>
          </span>
        </template>
        <template #cell()="data">
          <span class="d-block text-nowrap text-justify">
            {{ data.value }}
          </span>
        </template>
      </BTable>
      <div class="mx-2 mb-2">
        <BRow>
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </BCol>
          <!-- Pagination -->
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </BCol>
        </BRow>
      </div>
    </BCard>
  </BCard>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BMedia,
  BRow,
  BFormInput,
  BBadge,
  BPagination,
  BSpinner,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import Vue from 'vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BPagination,
    BBadge,
    BCol,
    BCard,
    BCardHeader,
    BMedia,
    BRow,
    BTable,
    vSelect,
    BFormInput,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'post.titulo',
          label: 'Post',
        },
        {
          key: 'review',
          label: 'Comentario',
        },
        {
          key: 'estado',
          label: 'Estado',
          tdClass: ['text-center'],
          thClass: ['text-center'],
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white'],
          thClass: ['text-center'],
        },
      ],
      sortBy: null,
    }
  },
  mounted() {
    this.getReviews()
  },
  methods: {
    clean() {
      this.items = []
    },
    approve(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea aprobar el comentario?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.aprobarDesaprobar(item.idReview, 'A', '')
        }
      })
    },
    dessaapprove(item) {
      Vue.swal({
        title: '¿Está seguro?',
        text: '¿Desea desaprobar el comentario?',
        icon: 'info',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        inputAttributes: {
          autocapitalize: 'on',
        },
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          await this.aprobarDesaprobar(item.idReview, 'D', result.value)
        }
      })
    },
    async aprobarDesaprobar(idReview, estado, reasons) {
      await store
        .dispatch('plataforma/REVIEWS_UPDATE', {
          idReview,
          estado,
          reasons,
        })
        .then(response => {
          /* FIND  in the array response.idReview */
          if (response.success) {
            const index = this.items.findIndex(
              item => item.idReview === response.result.idReview,
            )
            if (estado === 'D') {
              this.items.splice(index, 1)
              this.notify('Operación Exitosa', 'Comentario desaprobado', 'info')
            } else {
              this.items[index] = response.result
              this.notify('Operación Exitosa', 'Comentario aprobado', 'success')
            }
            this.getReviews()
          }
        })
        .catch(() => {
          this.notify(
            'Ha ocurrido un error',
            'Intentelo en otro momento.',
            'danger',
          )
        })
        .finally(() => {})
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const pageOptions = [10, 25, 50, 100]
    const items = ref([])

    const totalItems = ref(0)
    const totalPages = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const totalRows = ref(0)
    const isBusy = ref(true)
    const searchADR = ref([
      {
        text: 'Pendientes',
        value: 'R',
      },
      {
        text: 'Aprobados',
        value: 'A',
      },
      {
        text: 'Desaprobados',
        value: 'D',
      },
    ])
    const selectADR = ref({ text: 'Pendiente', value: 'R' })
    const getReviews = () => {
      isBusy.value = true
      store
        .dispatch('plataforma/REVIEWS_FIND_ALL', {
          estadoPublicacion: selectADR.value.value,
          limit: perPage.value,
          page: page.value,
          query: query.value,
          idPost: '-1',
        })
        .then(response => {
          items.value = response.items
          totalRows.value = response.totalRows
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
          from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
          to.value = perPage.value * (page.value - 1) + totalRows.value
          of.value = totalItems.value
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    watch([query, page, perPage, selectADR], () => {
      getReviews()
    })
    return {
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      from,
      to,
      of,
      totalRows,
      pageOptions,
      notify,
      getReviews,
      items,
      isBusy,
      searchADR,
      selectADR,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
